import React from "react";
import { useLanguageContent } from "../../Components/LangueContentLoader";
import dicePic from "../../Images/dice/dice.jpg";

export default function DiceGameProject() {
    const content = useLanguageContent();

    return (
        <main>
            <section>
                <h1 className="banner-text single-project-title">
                    {content.diceGameProject.title}&nbsp;
                    <p className="salmon">
                        {content.diceGameProject.project}
                    </p>
                    <a className="visit-link" target="_blank" href="https://codebyenzo.github.io/The-Dice-game/">
                        {content.voir_projet}
                    </a>
                </h1>
            </section>
            <section>
                <img className="img-projects" src={dicePic} alt="Screen shot of the website" />
            </section>
            <section>
                <h2>{content.Introduction}</h2>
                <p>
                    {content.diceGameProject.desc}
                </p>
            </section>
            <section>
                <h2>{content.diceGameProject.rulesTitle}</h2>
                <p>
                    {content.diceGameProject.desc}
                </p>
            </section>

        </main>
    );
}