import React from 'react';
import { useLanguageContent } from './LangueContentLoader';
import { NavLink } from 'react-router-dom';
const ProjectCard = (props) => {
    const { title, description, image, link } = props;
    const content = useLanguageContent();
    return (
        <div className="project-card">
            <h3>{title}</h3>
            <img className='project-img' src={image} alt={title} />
            <p className='project-description-text'>{description}</p>
            <NavLink className="btn" to={link}> {content.seeMore} </NavLink>

        </div >

    )
};
export default ProjectCard