
import React, { useRef } from "react";
import { useLanguageContent } from "../../Components/LangueContentLoader";
import logo from "../../Images/gaby-logo.jpg";
import vid1 from '../../Videos/v1.mp4';
import vid2 from '../../Videos/v2.mp4';
import vid3 from '../../Videos/v3.mp4';
import vid4 from '../../Videos/v4.mp4';
import vid5 from '../../Videos/v5.mp4';
import vid6 from '../../Videos/v6.mp4';
import vid7 from '../../Videos/v7.mp4';
import vid8 from '../../Videos/v8.mp4';
import vid9 from '../../Videos/v9.mp4';

export default function GabyProject() {
    const content = useLanguageContent();
    const videoRefs = useRef([]);

    const handlePlay = (index) => {
        videoRefs.current.forEach((video, idx) => {
            if (idx !== index && video) {
                video.pause();
            }
        });
    };

    const videoSources = [vid1, vid2, vid3, vid4, vid5, vid6, vid7, vid8, vid9];

    return (
        <main className="gaby-single-project">
            <section>
                <h1 className="banner-text single-project-title">
                    {content.GabyProject.title}
                    <a className="visit-link" target="_blank" href="https://gaby.paris/">
                        {content.GabyProject.visit}
                    </a>
                </h1>
                <h2>What is it ?!</h2>
                <p>
                    {content.GabyProject.desc}
                </p>
            </section>
            <section className="video-container">
                {videoSources.map((videoSrc, index) => (
                    <video
                        key={index}
                        controls
                        ref={el => videoRefs.current[index] = el}
                        onPlay={() => handlePlay(index)}
                        playsInline
                        
                    >
                        <source src={videoSrc} type="video/mp4" />
                        Votre navigateur ne supporte pas la balise vidéo.
                    </video>
                ))}
            </section>
        </main>
    );
}