import React, { useRef, useEffect } from "react";
import * as THREE from "three";

const MyThreeJSComponent = ({ imageUrl }) => {
    const imageContainerRef = useRef(null);
    const scene = useRef(null);
    const camera = useRef(null);
    const renderer = useRef(null);
    const planeMesh = useRef(null);
    const easeFactor = useRef(0.01);
    const mousePosition = useRef({ x: 0.5, y: 0.5 });
    const targetMousePosition = useRef({ x: 0.5, y: 0.5 });
    const aberrationIntensity = useRef(0.0);
    const prevPosition = useRef({ x: 0.5, y: 0.5 });

    const vertexShader = `
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `;

    const fragmentShader = `
        varying vec2 vUv;
        uniform sampler2D u_texture;    
        uniform vec2 u_mouse;
        uniform vec2 u_prevMouse;
        uniform float u_aberrationIntensity;

        void main() {
            vec2 gridUV = floor(vUv * vec2(20.0, 20.0)) / vec2(20.0, 20.0);
            vec2 centerOfPixel = gridUV + vec2(1.0/20.0, 1.0/20.0);
            
            vec2 mouseDirection = u_mouse - u_prevMouse;
            
            vec2 pixelToMouseDirection = centerOfPixel - u_mouse;
            float pixelDistanceToMouse = length(pixelToMouseDirection);
            float strength = smoothstep(0.3, 0.0, pixelDistanceToMouse);
     
            vec2 uvOffset = strength * - mouseDirection * 0.2;
            vec2 uv = vUv - uvOffset;

            vec4 colorR = texture2D(u_texture, uv + vec2(strength * u_aberrationIntensity * 0.01, 0.0));
            vec4 colorG = texture2D(u_texture, uv);
            vec4 colorB = texture2D(u_texture, uv - vec2(strength * u_aberrationIntensity * 0.01, 0.0));

            gl_FragColor = vec4(colorR.r, colorG.g, colorB.b, 1.0);
        }
    `;

    useEffect(() => {
        if (!imageContainerRef.current) return;

        const initializeScene = (texture) => {
            scene.current = new THREE.Scene();

            camera.current = new THREE.PerspectiveCamera(
                80,
                imageContainerRef.current.offsetWidth / imageContainerRef.current.offsetHeight,
                0.2,
                100
            );
            camera.current.position.z = 1;

            const shaderUniforms = {
                u_mouse: { type: "v2", value: new THREE.Vector2() },
                u_prevMouse: { type: "v2", value: new THREE.Vector2() },
                u_aberrationIntensity: { type: "f", value: 0.0 },
                u_texture: { type: "t", value: texture }
            };

            planeMesh.current = new THREE.Mesh(
                new THREE.PlaneGeometry(2, 2),
                new THREE.ShaderMaterial({
                    uniforms: shaderUniforms,
                    vertexShader,
                    fragmentShader
                })
            );

            scene.current.add(planeMesh.current);

            renderer.current = new THREE.WebGLRenderer();
            renderer.current.setSize(imageContainerRef.current.offsetWidth, imageContainerRef.current.offsetHeight);

            imageContainerRef.current.appendChild(renderer.current.domElement);
        };
        initializeScene(new THREE.TextureLoader().load(imageUrl));

        /**
         * Function to animate the scene by updating mouse position and rendering the scene.
         */
        const animateScene = () => {
            requestAnimationFrame(animateScene);
            mousePosition.current.x += (targetMousePosition.current.x - mousePosition.current.x) * easeFactor.current;
            mousePosition.current.y += (targetMousePosition.current.y - mousePosition.current.y) * easeFactor.current;
            if (planeMesh.current) {
                planeMesh.current.material.uniforms.u_mouse.value.set(mousePosition.current.x, 1.0 - mousePosition.current.y);
                planeMesh.current.material.uniforms.u_prevMouse.value.set(prevPosition.current.x, 1.0 - prevPosition.current.y);
                planeMesh.current.material.uniforms.u_aberrationIntensity.value = aberrationIntensity.current;
            }

            if (renderer.current && scene.current && camera.current) {
                renderer.current.render(scene.current, camera.current);
            }
        };
        animateScene();


        const handleMouseMove = (event) => {
            easeFactor.current = 0.02;
            const rect = imageContainerRef.current.getBoundingClientRect();
            prevPosition.current = { ...targetMousePosition.current };

            targetMousePosition.current.x = (event.clientX - rect.left) / rect.width;
            targetMousePosition.current.y = (event.clientY - rect.top) / rect.height;

            aberrationIntensity.current = 1;
        };

        const handleMouseEnter = (event) => {
            easeFactor.current = 0.02;
            const rect = imageContainerRef.current.getBoundingClientRect();

            mousePosition.current.x = targetMousePosition.current.x = (event.clientX - rect.left) / rect.width;
            mousePosition.current.y = targetMousePosition.current.y = (event.clientY - rect.top) / rect.height;
        };


        const handleMouseLeave = () => {
            easeFactor.current = 0.05;
            targetMousePosition.current = { ...prevPosition.current };
        };

        const container = imageContainerRef.current;

        container.addEventListener("mousemove", handleMouseMove);
        container.addEventListener("mouseenter", handleMouseEnter);
        container.addEventListener("mouseleave", handleMouseLeave);

        return () => {
            container.removeEventListener("mousemove", handleMouseMove);
            container.removeEventListener("mouseenter", handleMouseEnter);
            container.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, [imageUrl]); 

    return (
        <div ref={imageContainerRef} id="imageContainer">
            <img id="myImage" src={imageUrl} alt="Ec" />
        </div>
    );
};


export default MyThreeJSComponent;



