import React from "react";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import NotFound from "./Pages/NotFound";
import Projects from "./Pages/Projects";
import QuaiProject from "./Pages/SingleProjects/QuaiProject";
import PhotographerProject from "./Pages/SingleProjects/PhotographerProject";
import AssoProject from "./Pages/SingleProjects/AssoProject";
import GabyProject from "./Pages/SingleProjects/GabyProject";
import DiceProject from "./Pages/SingleProjects/DiceGameProject";
import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./Components/Navbar";
import { LanguageProvider } from "./Components/LanguageContext";
import Footer from "./Components/Footer";
import { AnimatePresence } from 'framer-motion';

export default function WebSite() {
    const location = useLocation();
    return (
        <LanguageProvider>
            <NavBar />
            <AnimatePresence initial={false} mode={"wait"}>
                <Routes location={location} key={location.pathname}>
                    {/* PAGES */}
                    <Route path="/" element={< Home />} />
                    <Route path='/Contact' element={< Contact />} />
                    <Route path='/About' element={< About />} />
                    <Route path='/Projects' element={< Projects />} />
                    {/* Single Projects */}
                    <Route path='/QuaiProject' element={< QuaiProject />} />
                    <Route path='/PhotographerProject' element={< PhotographerProject />} />
                    <Route path='/AssoProject' element={< AssoProject />} />
                    <Route path='/DiceGameProject' element={< DiceProject />} />
                    <Route path='/GabyProject' element={< GabyProject />} />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </AnimatePresence>
            <Footer />
        </LanguageProvider>

    );
}