import React from "react";
import { useLanguageContent } from "../../Components/LangueContentLoader";
import charte from "../../Images/Photographer/Charte-graphique_page-0001(1).jpg";
import maquette2 from "../../Images/Photographer/Maquette_page-0002.jpg";
import maquette4 from "../../Images/Photographer/Maquette_page-0004.jpg";
import maquette6 from "../../Images/Photographer/Maquette_page-0006.jpg";
import maquette7 from "../../Images/Photographer/Maquette_page-0007.jpg";

export default function PhotographerProject() {
    const content = useLanguageContent();
    return (
        <main>
            <section>
                <h1 className="banner-text single-project-title">
                    {content.Projet}&nbsp;
                    <p className="salmon">
                        {content.Photographe}
                    </p>
                    <a className="visit-link" target="_blank" href="https://charles-cantin-photograph-00.netlify.app/">
                        {content.voir_projet}
                    </a>
                </h1>
                <h2>{content.Introduction}</h2>
                <p>
                    {content.Contenu_Introduction}
                </p>
            </section>
            <section>
                <img className="img-projects" src={maquette2} alt="Mock up of home page's project" />
            </section>
            <section>
                <h2>{content.Objectifs}</h2>
                <p>
                    {content.Contenu_Objectifs.map((objectif, index) => (
                        <li key={index}>- {objectif}</li>
                    ))}
                </p>
            </section>
            <section>
                <img className="img-projects" src={maquette4} alt="Mock up of the galery's page" />
            </section>

            <section>
                <h2>{content["Charte_Graphique"]}</h2>
                <p>
                    {content.Contenu_Charte_Graphique}
                </p>
            </section>
            <section>
                <img className="img-projects wireframes-img" src={charte} alt="graphique charte" />
            </section>
            <section>
                <h2>{content.PhotographerProject.mockup}</h2>
                <p>
                    {content.PhotographerProject.desc}
                </p>
            </section>
            <section className="wireframes-images-box">
                <img className="img-projects double-img" src={maquette6} alt="Mock up of the price page, desktop version" />
                <img className="img-projects double-img" src={maquette7} alt="Mock up of the price page, mobile version" />
            </section>
        </main>
    );
}