import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

function SkillsComponent(props) {
    const [progress, setProgress] = useState(0);
    const [ref, inView] = useInView({});

    useEffect(() => {
        if (inView && progress < props.percent) {
            const timer = setTimeout(() => {
                setProgress(prevProgress => prevProgress + 1);
            }, 10);
            return () => clearTimeout(timer);
        }
    }, [inView, progress, props.percent]);

    return (
        <div className="skill" ref={ref}>
            <h4 className='skillTitle'>{props.name}</h4>
            <div className="progress-bar">
                <motion.div
                    className="progress"
                    style={{ width: `${progress}%` }}
                    initial="hidden"
                    whileInView={'show'}
                    viewport={{ once: false, amount: 0.5 }}
                >

                </motion.div>
            </div>
        </div>
    );
}
export default SkillsComponent;