import React from 'react';

class PulsatingText extends React.Component {
    render() {
        const { text } = this.props;
        const words = text.split(' ');

        return (
            <p className="pulsating-text">
                {words.map((word, index) => (
                    <React.Fragment key={index}>
                        <span className="pulsating-word">
                            {word.split('').map((letter, index) => (
                                <span key={index} className="pulsating-letter">{letter}</span>
                            ))}
                        </span>
                        {index !== words.length - 1 && ' '}
                    </React.Fragment>
                ))}
            </p>
        );
    }
}

export default PulsatingText;
