import React from "react";
import { useLanguageContent } from "../../Components/LangueContentLoader";
import imgQuai2 from "../../Images/quai-img/quai2.png";
import charte from "../../Images/quai-img/Charte-graphique_page-0001.jpg";
import zoning from "../../Images/quai-img/ZONING_page-0001.jpg";
import wireframe from "../../Images/quai-img/WIREFRAMES_page-0001.jpg";
import responsive from "../../Images/quai-img/Responsive.png";
import uml from "../../Images/quai-img/reseumeUML1.png";
import back from "../../Images/quai-img/backPic.png";


export default function QuaiProject() {
    const content = useLanguageContent();
    return (
        <main className="main-projects">
            <section>
                <h1 className="banner-text single-project-title">
                    Le Quai&nbsp;
                    <p className="salmon">
                        Antique
                    </p>
                    <a className="visit-link" rel="noreferrer" target="_blank" href="https://le-quai-antique.ec-bootstrap.com/">
                        {content.voir_projet}
                    </a>
                </h1>

                <h2>{content.quaiProject.Introduction}</h2>
                <p>
                    {content.quaiProject.Contenu_Introduction}
                </p>
            </section>
            <section>
                <img className="img-projects" src={imgQuai2} alt="Screen shot of the Le Quai Antique website" />
            </section>
            <section>
                <h2>{content.quaiProject.Conception}</h2>
                <p>
                    {content.quaiProject.Contenu_Conception}
                </p>
            </section>
            <section>
                <img className="img-projects" src={charte} alt="Graphique chart of the website" />
            </section>
            <section>
                <h2>UI/UX</h2>
                <p>
                    {content.quaiProject.Contenu_UI_UX}
                </p>
            </section>
            <section className="wireframes-images-box">
                <img className="img-projects double-img" src={zoning} alt="Zoning of the project" />
                <img className="img-projects double-img" src={wireframe} alt="Wireframe of the project" />
            </section>
            <section>
                <h2>Unified Modeling Language</h2>
                <p>
                    {content.quaiProject.Contenu_UML}
                </p>
            </section>
            <section>
                <img className="img-projects" src={uml} alt="Unified Modeling Language" />
            </section>
            <section>
                <h2>{content.quaiProject.Front_end_et_Responsive}</h2>
                <p>
                    {content.quaiProject.Contenu_Front_end_et_Responsive}
                </p>
            </section>
            <section>
                <img className="img-projects" src={responsive} alt="Image of website on different devices" />
            </section>
            <section>
                <h2>Back-end</h2>
                <p>
                    {content.quaiProject.Contenu_Back_end}
                </p>
            </section>
            <section>
                <img className="img-projects" src={back} alt="screen shot of the back-end inteface" />
            </section>
        </main >
    )
}   