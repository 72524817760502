import React, { useEffect, useState, useRef } from 'react';

const Loader = ({ onLoadingComplete }) => {
    const [progress, setProgress] = useState(0);
    const [currentElement, setCurrentElement] = useState(null);
    const [loadingComplete, setLoadingComplete] = useState(false);


    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(prevProgress => prevProgress + 1);
            if (progress >= 100) {
                clearInterval(interval);
                setLoadingComplete(true);
                onLoadingComplete();
            }
        }, 10);
        return () => clearInterval(interval);
    }, [progress, onLoadingComplete]);


    return (
        <div>
            {!loadingComplete ? (
                <div className="loader-box">
                    <div className="container-loader">
                        <ul className="loader">
                            <li className="loader-li loader-li1"></li>
                            <li className="loader-li loader-li2"></li>
                            <li className="loader-li loader-li3"></li>
                            <li className="loader-li loader-li4"></li>
                            <li className="loader-li loader-li5"></li>
                            <li className="loader-li loader-li6"></li>
                        </ul>
                    </div>
                    <h1>Loading... {progress.toFixed(2)}%</h1>
                    {currentElement && <ul><li>{currentElement}</li></ul>}
                </div>
            ) : null}
        </div>
    );
};

export default Loader;
