import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../Components/LanguageContext";
import { useLanguageContent } from "../Components/LangueContentLoader";
import { NavLink } from "react-router-dom";

export default function Footer() {
    const navigate = useNavigate();
    const { selectedLanguage, setSelectedLanguage } = useLanguage();
    const content = useLanguageContent();

    // Fonction pour faire défiler la page vers le haut
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <footer>
            <article>
                <ul className="footer-nav">
                    <li className="footer-list">
                        <NavLink
                            to="/"
                            className="footer-links"
                            onClick={() => { scrollToTop(); navigate('/'); }}
                        >
                            {content.home}
                        </NavLink>
                    </li>
                    <li className="footer-list">
                        <NavLink
                            to="/projects"
                            className="footer-links"
                            onClick={() => { scrollToTop(); navigate('/projects'); }}
                        >
                            {content.projects}
                        </NavLink>
                    </li>
                    <li className="footer-list">
                        <NavLink
                            to="/contact"
                            className="footer-links"
                            onClick={() => { scrollToTop(); navigate('/contact'); }}
                        >
                            {content.contact}
                        </NavLink>
                    </li>
                </ul>
            </article>
            <article>
                <div className="socials">
                    <a className="socials-icons" href="https://github.com/CodeByEnzo" target="_blank" rel="noreferrer">
                        <i className="fa-brands fa-github social-icon"> <FaGithub /></i>
                    </a>
                    <a className="socials-icons" href="https://www.linkedin.com/in/enzo-capitanio/" target="_blank" rel="noreferrer">
                        <i className="fa-brands fa-linkedin social-icon socials-icons"><FaLinkedin className="socials-icons" /></i>
                    </a>
                </div>
            </article>

            <div className="copyrights">
                <h3 className="footer-title">Portfolio  </h3>
                <p className="copyrights-text"> &nbsp;By Enzo Copyright © 2024 - All Rights Reserved</p>
            </div>
        </footer>
    );
}
