import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import WebSite from './WebSite';
import Loader from './Components/Loader';

function App() {
    const [loading, setLoading] = useState(true);

    const handleLoadingComplete = () => {
        setLoading(false);
    };

    return (
        <div className="App">
            {loading ? (
                <Loader onLoadingComplete={handleLoadingComplete} />
            ) : (
                <BrowserRouter>
                    <WebSite />
                </BrowserRouter>
            )}
        </div>
    );
}

export default App;
