import React from "react";
import { useLanguageContent } from "../Components/LangueContentLoader";
import ProjectCard from "../Components/ProjectCard";
import hi from "../Images/hero1.png";
import gabLogo from "../Images/GabyPresent.png";
import photographe from "../Images/photographe.jpg";
import quai from "../Images/quai-img/quai.jpg";
import asso from "../Images/asso.jpg";
import quai2 from "../Images/quai-img/quai2.png";
import dice from "../Images/dice.jpg";
import backpic from "../Images/quai-img/backPic.png";
import maquettes from "../Images/quai-img/maquettes.png";
import responsiv from "../Images/quai-img/Responsive.png";
import { motion } from 'framer-motion';

export default function Projects() {
    const content = useLanguageContent();
    return (
        <motion.main
            className="project-section"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
        >

            <article className="article-project">
                <p className="banner-text">{content.my}&nbsp;</p>
                <h1 className="banner-text salmon">{content.projects}</h1>
            </article>
            <article className="carousel-box">
                <div class="carousel-container">
                    <div id="carousel">
                        <figure><img className="img-carousel" src={hi} alt="" /></figure>
                        <figure><img className="img-carousel" src={photographe} alt="" /></figure>
                        <figure><img className="img-carousel" src={quai} alt="" /></figure>
                        <figure><img className="img-carousel" src={quai2} alt="" /></figure>
                        <figure><img className="img-carousel" src={dice} alt="" /></figure>
                        <figure><img className="img-carousel" src={backpic} alt="" /></figure>
                        <figure><img className="img-carousel" src={maquettes} alt="" /></figure>
                        <figure><img className="img-carousel" src={asso} alt="" /></figure>
                        <figure><img className="img-carousel" src={responsiv} alt="" /></figure>
                    </div>
                </div>
            </article>
                <h2 className="projects-titles"><hr/>Marketing Projects</h2>
            <section className="projects-parts">
                <article className="mt-50">
                    <ProjectCard
                        title="Gaby Paris"
                        description={content.GabyProject.resume}
                        image={gabLogo}
                        link="/GabyProject"
                    />
                </article>
            </section>
                <h2 className="projects-titles"><hr/>Dev Projects</h2> 
            <section className="projects-parts dev-box">
                <article className="dev-project-resume">
                    <ProjectCard
                        title="Photographe"
                        description={content.photographerResume}
                        image={photographe}
                        link="/PhotographerProject"
                    />
                </article>
                <article className="dev-project-resume">
                    <ProjectCard
                        title="Le Quai Antique"
                        description={content.quaiResume}
                        image={quai}
                        link="/QuaiProject"
                    />
                </article>
                <article className="dev-project-resume">
                    <ProjectCard
                        title="Les Animaux Du 27"
                        description={content.assoResume}
                        image={asso}
                        link="/AssoProject"
                    />

                </article>
                <article className="dev-project-resume">
                    <ProjectCard
                        title="The Dice Game"
                        description={content.diceGameResume}
                        image={dice}
                        link="/DiceGameProject"
                    />
                </article>
            </section>


        </motion.main>
    );
}