import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useLanguage } from "../Components/LanguageContext";
import { useLanguageContent } from "../Components/LangueContentLoader";
import logo from "../Images/LOGO.png";

export default function NavBar() {
    const { selectedLanguage, setSelectedLanguage } = useLanguage();
    const content = useLanguageContent();
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (menuOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [menuOpen]);

    const handleLanguageChange = (e) => {
        setSelectedLanguage(e.target.value);
    };

    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLinkClick = () => {
        window.scrollTo(0, 0);
        setMenuOpen(false);
    };

    return (
        <nav className={`navbar ${menuOpen ? "open" : ""}`}>
            {location.pathname === "/" ?
                <div className="logoContainer">
                    <img className="logo" src={logo} alt="Logo" />
                </div>
                :
                <a className="logoContainer" href="/">
                    <img className="logo" src={logo} alt="Logo" />
                </a>
            }

            <div className="burger-menu" onClick={handleToggleMenu}>
                <div className="bars-container">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
            </div>
            <ul className={`nav-links ${menuOpen ? "open" : "close"}`}>
                <li>
                    <NavLink to="/" className={"link-menu"} onClick={handleLinkClick}>
                        {content.home}
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/Projects" className={"link-menu"} onClick={handleLinkClick}>
                        {content.projects}
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/About" className={"link-menu"} onClick={handleLinkClick}>
                        {content.about}
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/Contact" className={"link-menu"} onClick={handleLinkClick}>
                        {content.contact}
                    </NavLink>
                </li>
                <li>
                    <select id="language-select" value={selectedLanguage} onChange={handleLanguageChange}>
                        <option className="optionsLang" value="en">🇬🇧 Eng</option>
                        <option className="optionsLang" value="fr">🇫🇷 Fr</option>
                    </select>
                </li>
            </ul>
        </nav>
    );
}
