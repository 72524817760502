import React from "react";
import { useLanguageContent } from "../Components/LangueContentLoader";
import { TypeAnimation } from "react-type-animation";
import PulsatingText from "../Components/PulsatingText";
import hero from '../Images/hero.png';
import MyThreeJSComponent from "../Components/MyThreeJSComponent";
import MouseTrail from "../Components/MouseTrail";
import SkillsSection from "../Components/SkillsSection";
import { motion } from 'framer-motion';

export default function Home() {
    const content = useLanguageContent();
    return (
        <motion.main
            id='Home'
            className="home-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
        >
            <div className="home-banner">
                <MouseTrail />
                <div className="home-desc">
                    <div className="descBox">
                        <div>
                            <h2 className="homeTitle noWrap">{content.homeTitle}&nbsp;</h2>
                            <h2 className="homeName">{content.name}</h2>
                        </div>
                        {/* <h2 className='banner-text'>
                            <PulsatingText text={content.homeDesc} />
                        </h2>
                        <br />
                        <TypeAnimation
                            sequence={[
                                content.homeAnimation1,
                                1500,
                                content.homeAnimation2,
                                1500,
                            ]}
                            speed={50}
                            className='descTxt'
                            repeat={Infinity}
                            wrapper='span'

                        /> */}

                        < h1 className='banner-text'>
                            <PulsatingText text={content.welcome_message} />
                        </h1>

                    </div>
                </div>
                <div className="hero-box">
                    <div className="hero-container">
                        <MyThreeJSComponent imageUrl={hero} />
                    </div>
                </div>

            </div>
            <div className="home-content">
                <SkillsSection />
            </div>


        </motion.main>

    );
}