import React, { useEffect, useRef } from 'react';
/**
 * Renders a mouse trail on a canvas element.
 *
 * @return {JSX.Element} The canvas element with the mouse trail.
 */
const MouseTrail = () => {
    const canvasRef = useRef(null);
    const mouseX = useRef(0);
    const mouseY = useRef(0);
    const trails = useRef([]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;
        /**
         * Updates the mouse position based on the given event.
         *
         * @param {MouseEvent} event - The mouse event.
         * @return {void} This function does not return anything.
         */
        const updateMousePosition = (event) => {
            mouseX.current = event.clientX - canvas.offsetLeft;
            mouseY.current = event.clientY - canvas.offsetTop;
        };
        /**
         * Draws the mouse trail on the canvas.
         */
        const draw = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            trails.current.push({ x: mouseX.current, y: mouseY.current });

            if (trails.current.length > 50) {
                trails.current.shift();
            }
            
            for (let i = 0; i < trails.current.length; i++) {
                const trail = trails.current[i];
                const opacity = 1 - (i / trails.current.length);
                ctx.beginPath();
                ctx.moveTo(trail.x, trail.y);
                ctx.lineTo(mouseX.current, mouseY.current);
                ctx.strokeStyle = `rgba(255, 255, 255, ${opacity})`;
                ctx.stroke();
            }

            animationFrameId = requestAnimationFrame(draw);
        };

        const init = () => {
            canvas.addEventListener('mousemove', updateMousePosition);
            draw();
        };

        init();

        return () => {
            cancelAnimationFrame(animationFrameId);
            canvas.removeEventListener('mousemove', updateMousePosition);
        };
    }, []);

    return (
        <canvas
            ref={canvasRef}
            width={window.innerWidth}
            height={window.innerHeight}
            style={{ width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 0 }}
        />
    );
};

export default MouseTrail;
