import React from 'react';
import Skill from './SkillsComponent';
import { useLanguageContent } from "../Components/LangueContentLoader";
import { GiSparkles } from "react-icons/gi";

export default function SkillsSection() {
    const content = useLanguageContent();
    return (
        <div id='Skills-section'>
            <section id='skillsList'>
                <div className='skillsBox'>
                    <h2>{content.skills}</h2>
                    <h3 className='skillsTitle'>Languages</h3>
                    <Skill name="HTML/CSS" percent={90} />
                    <Skill name="JavaScript" percent={70} />
                    <Skill name="PHP" percent={60} />
                    <Skill name="MYSQL" percent={80} />
                    <Skill name="PYTHON" percent={10} />
                    <h3 className='skillsTitle'>Frameworks</h3>
                    <Skill name="React" percent={70} />
                    <Skill name="Bootstrap" percent={90} />
                    <h3 className='skillsTitle'>CMS</h3>
                    <Skill name="WORDPRESS" percent={80} />
                    <Skill name="NETLIFY" percent={40} />
                    <h3 className='skillsTitle'>Softwares</h3>
                    <Skill name="Photoshop" percent={80} />
                    <Skill name="Illustrator" percent={40} />
                    <Skill name="PremièrePro" percent={40} />
                    <Skill name="CapCut" percent={80} />
                    <Skill name="FruityLoops" percent={70} />
                    <Skill name="WORDS" percent={80} />
                    <Skill name="EXCEL" percent={30} />

                </div>
            </section>
            <section>
                <h2>
                    {content.whatsup}
                </h2>
                <p>
                    {content.whatsupText}
                </p>
            </section>
            <section>
                <GiSparkles className='sparks-icons' />
                <h2>
                    {content.qualification}
                </h2>
                <h3 className='white'>
                    - {content.webDegree}
                </h3>
                <h3 className='white'>
                    - {content.restaurationDegree}
                </h3>
                <GiSparkles className='sparks-icons' />
            </section>
            <section>
                <h2>
                    {content.experience}
                </h2>
                <div className='EXP-section'>
                    <div class="experience">
                        <div>
                            <div class="annee">2023</div>
                            <div>Nova-SEO ({content.resume.month6})</div>
                            <div> {content.commercial} </div>
                            <div>Normandie (14, 27, 50, 61, 76)</div>
                        </div>
                    </div>
                    <div class="ligne-verticale"></div>

                    <div class="experience">
                        <div>
                            <div class="annee">2022</div>
                            <div>Opinel ({content.resume.month7})</div>
                            <div>{content.agentMontage}</div>
                            <div>Chambéry (73)</div>
                        </div>
                    </div>
                    <div class="ligne-verticale"></div>

                    <div class="experience">
                        <div>
                            <div class="annee">2021</div>
                            <div>Le RDC Discothèque ({content.resume.month3})</div>
                            <div>{content.serveur}/{content.barman}</div>
                            <div>Chambéry (73)</div>
                        </div>
                    </div>
                    <div class="ligne-verticale"></div>

                    <div class="experience">
                        <div>
                            <div class="annee">2019/2020</div>
                            <div>L'Ancolie**** ({content.resume.year1})</div>
                            <div>{content.serveur}/{content.maitreDho}</div>
                            <div>Cruseilles (74)</div>
                        </div>
                    </div>
                    <div class="ligne-verticale"></div>

                    <div class="experience">
                        <div>
                            <div class="annee">2019</div>
                            <div>- Les Servages d'Armelle**** ({content.resume.month4})</div>
                            <div>{content.chefDeRang}</div>
                            <div>Les Carroz d'Arraches (74)</div>
                        </div>
                    </div>
                    <div class="ligne-verticale"></div>

                    <div class="experience">
                        <div>
                            <div class="annee">2018/2019</div>
                            <div>Au Bureau ({content.resume.month4})</div>
                            <div>{content.chefDeRang}</div>
                            <div>Val De Reuil (27)</div>
                        </div>
                    </div>
                    <div class="ligne-verticale"></div>

                    <div class="experience">
                        <div>
                            <div class="annee">2018</div>
                            <div>Columbus hôtel**** ({content.resume.month2})</div>
                            <div>{content.manager}</div>
                            <div>Monaco</div>
                        </div>
                    </div>
                    <div class="ligne-verticale"></div>

                    <div class="experience">
                        <div>
                            <div class="annee">2016/2018</div>
                            <div>Au Bureau ({content.resume.year2})</div>
                            <div>{content.chefDeRang}</div>
                            <div>Annecy (74)</div>
                        </div>
                    </div>
                    <div class="ligne-verticale"></div>

                    <div class="experience">
                        <div>
                            <div class="annee">2015</div>
                            <div>Intérimaire, Multiples hôtels 5 étoiles ({content.resume.month6})</div>
                            <div>{content.chefDeRang}/{content.barman}/{content.traiteur}</div>
                            <div>{content.geneva}</div>
                        </div>
                    </div>
                    <div class="ligne-verticale"></div>

                    <div class="experience">
                        <div>
                            <div class="annee">2015</div>
                            <div>Impérial Palace**** ({content.resume.month6})</div>
                            <div>{content.chefDeRang}</div>
                            <div>Annecy (74)</div>
                        </div>
                    </div>
                    <div class="ligne-verticale"></div>

                    <div class="experience">
                        <div>
                            <div class="annee">2014</div>
                            <div>L'auberge du Lyonnais ({content.resume.year1})</div>
                            <div>{content.serveurPo}</div>
                            <div>Annecy (74)</div>
                        </div>
                    </div>
                    <div class="ligne-verticale"></div>

                    <div class="experience">
                        <div>
                            <div class="annee">2013</div>
                            <div>Hôtel Belle Pierre**** ({content.resume.month1})</div>
                            <div>{content.serveur}</div>
                            <div>{content.LaReunion}</div>
                        </div>
                    </div>
                    <div class="ligne-verticale"></div>

                    <div class="experience">
                        <div>
                            <div class="annee">2010/2012</div>
                            <div>Thaï-Savanh' ({content.resume.year2})</div>
                            <div>{content.serveurPo}</div>
                            <div>{content.SXM}</div>
                        </div>
                    </div>
                </div>


            </section>
        </div>
    );
}
