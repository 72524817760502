import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import emailjs from '@emailjs/browser';
import { useLanguageContent } from "../Components/LangueContentLoader";
import { motion } from 'framer-motion';

export default function Contact() {
    const content = useLanguageContent();
    const schema = yup
        .object({
            name: yup
                .string()
                .min(5)
                .max(50)
                .required('Your name is required'),
            email: yup
                .string()
                .email('Use a valid email')
                .max(255)
                .required('An email is required'),
            message: yup.string().required('A message is required')
                .min(10, "At least 10 characters")
                .max(250, "Maximum 250 characters"),
            other: yup
                .string(),
        })
        .required();

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = (data, r) => {
        if (data.other !== '') {
            return
        }
        alert('😀 Thank you for contacting me ! I will answer as soon as possible 😀');
        const templateId = 'template_wwogqs9';
        const serviceId = 'service_g6p4hj4';
        sendFeedback(serviceId, templateId, {
            name: data.name,
            email: data.email,
            message: data.message,
            reply_to: r.target.reset(),
        });
    };
    const sendFeedback = (serviceId, templateId, variables) => {
        emailjs
            .send(serviceId, templateId, variables, '2DSLI8rlHCvlzWYJH')
            .then((res) => {
                console.log('succes');
            })
            .catch((err) => console.error('There\'s an error...'));
    };

    return (
        <motion.main
            id='contact'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}>
            <div className="wrap-contact">
                <div className="contact-header">
                    <h2>Contact</h2>
                    <p className='p10'>
                        {content.contact_content}
                    </p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} name="contact" className="contact-form" method="post" action="">

                    <div className="wrap-input" data-validate="Please enter your name">
                        <input className="input" type="text" name="name" placeholder="Full Name" {...register('name')} />
                        {errors.name && <p className='errorMessage' id="c-yup">{errors.name.message}</p>}
                    </div>

                    <div className="wrap-input" data-validate="Please enter your email">
                        <input className="input" type="text" name="email" placeholder="E-mail" {...register('email')} />
                        {errors.email && <p className='errorMessage' id="c-yup">{errors.email.message}</p>}
                    </div>

                    <div className="wrap-input" data-validate="Please enter your message">
                        <textarea className="input" type="text" name="message" placeholder="Your Message" {...register('message')}></textarea>
                        {errors.message && <p className='errorMessage' id="c-yup">{errors.message.message}</p>}
                    </div>
                    <div className="wrap-input" data-validate="Please enter your message">
                        <input className="input" type="hidden" name="other" placeholder="Other" {...register('other')} />
                        {errors.message && <p className='errorMessage' id="c-yup">{errors.message.message}</p>}
                    </div>
                    <button type="submit" className="btnSubmit">
                        {content.send}
                    </button>

                    <div className="infoBox">
                        <p className='contactInfo'>
                            Capitanio Enzo,
                            <br />Evreux (27000, France)
                        </p>

                    </div>

                </form>

            </div>

        </motion.main>
    )
}