import React from "react";
import hero from "../Images/hero.JPG";
import { motion } from 'framer-motion';
import { useLanguageContent } from "../Components/LangueContentLoader";

export default function About() {
    const content = useLanguageContent();
    return (
        <motion.main
            className="about-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
        >
            <section>
                <h1 className="banner-text">
                    {content.AboutSection.title}
                </h1>
                <p className="p30">
                    {content.AboutSection.content}
                </p>
                <h2>{content.AboutSection.title2}</h2>
                <p className="p30">
                    {content.AboutSection.next}
                </p>
            </section>
            <section>
                <div className="img_about_container">
                    <img className="img-projects" src={hero} alt="" />
                </div>
            </section>
        </motion.main>
    );
}