// Pages/NotFound.js
import React from 'react';

export default function NotFound() {
    return (
        <div style={{
            textAlign: 'center', marginTop: '50px', minHeight: '50vh'
        }}>
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
        </div>
    );
}
