import React from "react";
import { useLanguageContent } from "../../Components/LangueContentLoader";
import asso from "../../Images/Asso/asso.jpg";

export default function AssoProject() {
    const content = useLanguageContent();
    return (
        <main className="main-projects">
            <section>
                <h1 className="banner-text single-project-title">
                    {content.assoProject.lesAnimaux} &nbsp;
                    <p className="salmon">
                        {content.assoProject.vingt_sept}
                    </p>
                    <a className="visit-link" target="_blank" href="https://codebyenzo.github.io/AssoStudi/">
                        Voir le site en ligne
                    </a>
                </h1>

                <h2>Introduction</h2>
                <p>
                    {content.assoProject.paragraphe1}
                </p>
            </section>
            <section>
                <img className="img-projects" src={asso} alt="Screen shot of the website" />
            </section>
            <section>
                <h2>{content.assoProject.website}</h2>
                <p>
                    {content.assoProject.paragraphe2}
                </p>
            </section>
        </main >
    );
}